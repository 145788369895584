import React, { useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "./decboofour.css";

// Import Swiper styles
import "swiper/css";

import "swiper/css/navigation";

// import required modules
import { Navigation } from "swiper";

import $ from "jquery";

const style1 = { display: "none" };

const imageZoom = async () => {
  $(".fa-angle-left").on("click", function () {
    $(".swiper-button-prev").click();
  });

  $(".fa-angle-right").on("click", function () {
    $(".swiper-button-next").click();
  });

  $(".swiper-button-next, .swiper-button-prev").attr("style", "display:none");

  $("body").keyup(function (e) {
    var code = e.keyCode || e.which; //Find the key pressed

    if (code == 39) {
      $(".fa-angle-right").click();
    }
    if (code == 37) {
      $(".fa-angle-left").click();
    }
  });

  $(".dbl-sub-sec").on("mouseenter", function () {
    $(this).find(".decor-tag-link").fadeIn();
  });

  $(".dbl-sub-sec").on("mouseleave", function () {
    $(this).find(".decor-tag-link").fadeOut();
  });

  /*document.getElementById('produto').style.display="none";*/
};

const Homediaries = () => {
  useEffect(() => {
    imageZoom();
  }, []);

  return (
    <Swiper
      slidesPerView={1}
      spaceBetween={20}
      modules={[Navigation]}
      navigation={true}
      className="mySwiper nexthd prvhd"
    >

<SwiperSlide className="d-flex align-items-center justify-content-center mt-4">
        <center>
          <div className="container-fluid dbl-2 mt-3 mb-3">
            <div className="row">
              <div className="col-12 col-md-12 pl-2 pr-2">
                <div className="h-100">
                  <img className="mt-1 heightimg" src="/images/2050/cover.png" />
                </div>
              </div>
            </div>
          </div>
        </center>
      </SwiperSlide>



      <SwiperSlide className="d-flex align-items-center justify-content-center mt-4">
        <div className="container dbl-2 mt-3 mb-3">
          <div className="row">
            <div className="col-12 col-md-12 pl-2 pr-2">
              <center>
                <div className="h-100">
                  <img className="w-100 h-100" src="/images/2050/1.png"></img>
                  <div className="decor-tag-link" style={{ display: "none" }}>
                    <div class="decor-tag">
                      <span class="dt-icon float-left">
                        <img src="/images/magnifying-glass-plus.png"></img>
                      </span>
                      <span class="dt-text float-right">View Decor</span>
                    </div>
                  </div>
                </div>
              </center>
            </div>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div className="container dbl-2 mt-3 mb-3">
          <div className="row">
            <p className="m-0">Decor Code: D003 Rombico</p>
            <div className="col-sm-12 col-md-7 pl-2 pr-0">
              <div className="dbl-main-sec h-100 mt-2">
                <a href="/decorsdetail/d003-rombico" target="_blank">
                  <img
                    className="lb-btn mt-1 mb-2 w-100 h-100"
                    src="/images/2050/2.1.png"
                  ></img>
                  <div className="decor-tag-link" style={{ display: "none" }}>
                    <div class="decor-tag">
                      <span class="dt-icon float-left">
                      <a href="/decorsdetail/d003-rombico" target="_blank">
                        <img src="/images/magnifying-glass-plus.png"></img></a>
                      </span>
                      <span class="dt-text float-right">View Decor</span>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-sm-5 col-md-5 pl-2 pr-0">
              <div className="dbl-sub-sec h-100 mt-2">
                <a href="/decorsdetail/d003-rombico" target="_blank">
                  <img
                    className="mt-1 w-100 h-100"
                    src="/images/2050/2.2.png"
                  ></img>
                  <div className="decor-tag-link" style={{ display: "none" }}>
                    <div class="decor-tag">
                      <span class="dt-icon float-left">
                      <a href="/decorsdetail/d003-rombico" target="_blank">
                        <img src="/images/magnifying-glass-plus.png"></img></a>
                      </span>
                      <span class="dt-text float-right">View Decor</span>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div className="container dbl-2 mt-3 mb-3">
          <div className="row">
            <p className="m-0">Decor Code: D002 Aspen Mosaico</p>
            <div className="col-sm-12 col-md-7 pl-2 pr-0">
              <div className="dbl-main-sec h-100 mt-2">
                <video class="w-100 h-100" controls autoplay>
                  <source
                    src="/videos/catalogs/2050/d002.mp4"
                    type="video/mp4"    
                  ></source>
                </video>
              </div>
            </div>
            <div className="col-sm-5 col-md-5 pl-2 pr-0">
              <div className="dbl-sub-sec h-100 mt-2">
                <a href="/decorsdetail/d002-aspen-mosaico" target="_blank">
                  <img
                    className="mt-1 w-100 h-100"
                    src="/images/2050/3.png"
                  ></img>
                  <div className="decor-tag-link" style={{ display: "none" }}>
                    <div class="decor-tag">
                      <span class="dt-icon float-left">
                      <a href="/decorsdetail/d002-aspen-mosaico " target="_blank">
                        <img src="/images/magnifying-glass-plus.png"></img>
                        </a>
                      </span>
                      <span class="dt-text float-right">View Decor</span>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div className="container dbl-2 mt-3 mb-3">
          <div className="row">
            <p className="m-0">Decor Code: D005 Claro Walnut</p>
            <div className="col-sm-12 col-md-7 pl-2 pr-0">
              <div className="dbl-main-sec h-100 mt-2">
                <a href="/decorsdetail/d005-claro-walnut" target="_blank">
                  <img
                    className="lb-btn mt-1 mb-2 w-100 h-100"
                    src="/images/2050/4.1.png"
                  ></img>
                  <div className="decor-tag-link" style={{ display: "none" }}>
                    <div class="decor-tag">
                      <span class="dt-icon float-left">
                      <a href="/decorsdetail/d005-claro-walnut" target="_blank">
                        <img src="/images/magnifying-glass-plus.png"></img></a>
                      </span>
                      <span class="dt-text float-right">View Decor</span>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-sm-5 col-md-5 pl-2 pr-0">
              <div className="dbl-sub-sec h-100 mt-2">
                <a href="/decorsdetail/d005-claro-walnut" target="_blank">
                  <img
                    className="mt-1 w-100 h-100"
                    src="/images/2050/4.2.png"
                  ></img>
                  <div className="decor-tag-link" style={{ display: "none" }}>
                    <div class="decor-tag">
                      <span class="dt-icon float-left">
                      <a href="/decorsdetail/d005-claro-walnut" target="_blank">
                        <img src="/images/magnifying-glass-plus.png"></img></a>
                      </span>
                      <span class="dt-text float-right">View Decor</span>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div className="container dbl-2 mt-3 mb-3">
          <div className="row">
            <p className="m-0">Decor Code: D001 Neo Tanzania</p>
            <div className="col-sm-12 col-md-7 pl-2 pr-0">
              <div className="dbl-main-sec h-100 mt-2">
                <video class="w-100 h-100" controls autoplay>
                  <source
                    src="/videos/catalogs/2050/d001.mp4"
                    type="video/mp4"    
                  ></source>
                </video>
              </div>
            </div>
            <div className="col-sm-5 col-md-5 pl-2 pr-0">
              <div className="dbl-sub-sec h-100 mt-2">
                <a href="/decorsdetail/d001-neo-tanzania" target="_blank">
                  <img
                    className="mt-1 w-100 h-100"
                    src="/images/2050/5.png"
                  ></img>
                  <div className="decor-tag-link" style={{ display: "none" }}>
                    <div class="decor-tag">
                      <span class="dt-icon float-left">
                      <a href="/decorsdetail/d001-neo-tanzania" target="_blank">
                        <img src="/images/magnifying-glass-plus.png"></img>
                        </a>
                      </span>
                      <span class="dt-text float-right">View Decor</span>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div className="container dbl-2 mt-3 mb-3">
          <div className="row">
            <p className="m-0">Decor Code: D004 Chevron Zest</p>
            <div className="col-sm-12 col-md-7 pl-2 pr-0">
              <div className="dbl-main-sec h-100 mt-2">
                <video class="w-100 h-100" controls autoplay>
                  <source
                    src="/videos/catalogs/2050/d004.mp4"
                    type="video/mp4"    
                  ></source>
                </video>
              </div>
            </div>
            <div className="col-sm-5 col-md-5 pl-2 pr-0">
              <div className="dbl-sub-sec h-100 mt-2">
                <a href="/decorsdetail/d004-chevron-zest" target="_blank">
                  <img
                    className="mt-1 w-100 h-100"
                    src="/images/2050/6.png"
                  ></img>
                  <div className="decor-tag-link" style={{ display: "none" }}>
                    <div class="decor-tag">
                      <span class="dt-icon float-left">
                      <a href="/decorsdetail/d004-chevron-zest" target="_blank">
                        <img src="/images/magnifying-glass-plus.png"></img>
                        </a>
                      </span>
                      <span class="dt-text float-right">View Decor</span>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>


      <SwiperSlide className="d-flex align-items-center justify-content-center mt-4">
        <center>
          <div className="container-fluid dbl-2 mt-3 mb-3">
            <div className="row">
              <div className="col-12 col-md-12 pl-2 pr-2">
                <div className="h-100">
                  <img className="mt-1 heightimg" src="/images/2050/back-cover.png" />
                </div>
              </div>
            </div>
          </div>
        </center>
      </SwiperSlide>


      <div className="text-center mt-3 catalog">
        <p>
          <i class="fa-solid fa-angle-left"></i>
          <i class="fa-solid fa-angle-right"></i>
        </p>
      </div>
    </Swiper>
  );
};

export default Homediaries;
