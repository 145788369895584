import React , { useEffect } from "react";
import Layout from "../components/Layout/Index";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Decboo1 from "../components/decbooone";
import Decboo2 from "../components/decbootwo";
import Decboo3 from "../components/decboothree";
import Decboo4 from "../components/decboofour";

const OurDesignStudios = () => {

  useEffect(() => {

    // Update Page title tag  
    document.title = "Al-Noor | Collection - 2021";

    // Select the meta tag with a name attribute of "property"
    const socialPageTitle = document.querySelector('meta[property="og:title"]');

    // Update the content attribute of the meta tag
    socialPageTitle.setAttribute('content', "Al-Noor | Collection - 2021");

    // Select the meta tag with a name attribute of "description"
    const metaDescription = document.querySelector('meta[name="description"]');

    // Update the content attribute of the meta tag
    metaDescription.setAttribute('content', "Take a closer look at our decor collections to get a feel for how they look.");

  }, []);

  return (
    <>
      <div style={{ boxShadow: "-7px 7px 45px 0px rgba(0, 0, 0, 0.1)" }}>
        <Header />
      </div>
      <p className="Submainhead">Collections</p>
      <p className="text-center m-3 h5 dbook-links"><a href="/decor-book-4">2050</a> <a style={{color:"grey"}} href="/decor-books">2021</a> <a style={{color:"grey"}} href="/decor-book-2">2020</a> <a style={{color:"grey"}} href="/decor-book-3">2019</a></p>
      <div className="Imgheight">
        <Tabs defaultActiveKey="first">
          <Tab eventKey="first" title="">
            <Decboo4 />
          </Tab>
         
        </Tabs>
      </div>

      <Footer />
    </>
  );
};

export default OurDesignStudios;
